.bg_table {
  background-color: #656565 !important;
  color: white;
}
td {
  border: 0px !important;
  font-size: 14px;
}
tr:nth-child(even) {
  background-color: #edf2f7;
}

.list_header {
  background-color: #f2f2f2;
}

.list_header--bottom {
  background-color: #f2f2f2;
  border-bottom: 1px solid #d6d6d6;
}
.list_content {
  padding: 0rem 10rem;
}

.companies-reject-modal {
  width: 100%;
}

.reject-model-des {
  margin-top: 20px;
  margin-bottom: 10px;
}

html[dir="rtl"] .reject-model-des {
  direction: rtl;
  text-align: right;
}

html[dir="rtl"] .companies-reject-modal .MuiInputLabel-filled {
  left: unset;
  right: 20px;
}

html[dir="rtl"] .companies-reject-modal .MuiFormHelperText-root.Mui-error {
  text-align: right !important;
}

.CompaniesInput {
  width: 100%;
}

.CompaniesInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  font-family: "Cairo" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.75);
  left: 25px !important;
  right: unset !important;
  transform-origin: top left !important;
}

html[dir="rtl"] .CompaniesInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  left: unset !important;
  right: 25px !important;
  transform-origin: top right !important;
}


html[dir="rtl"] .CompaniesInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  left: unset;
  right: 14px;
}

.CompaniesInput .MuiFilledInput-input {
  color: rgba(0, 0, 0, 0.65);
}

.CompaniesInput .MuiFilledInput-root {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-bottom: 15px !important;
}

.CompaniesInput .MuiFilledInput-root:hover {
  background-color: #fff;
}

.CompaniesInput .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}

.CompaniesInput .MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.CompaniesInput .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.CompaniesInput .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54);
}

.CompaniesInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}

.CompaniesInput .MuiFormHelperText-root.Mui-error {
  font-family: "Cairo" !important;
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

html[dir="rtl"] .CompaniesInput .MuiFormHelperText-root.Mui-error {
  text-align: right;
  margin-right: 0px !important;
}

.CompaniesInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}


.CompaniesInput div .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: 7px !important;
  left: unset !important;
  top: calc(50% - 0.3em) !important;
  width: 1.6em !important;
  height: 1.6em !important;
}

html[dir="rtl"] .CompaniesInput div .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: unset !important;
  left: 7px !important;
  top: calc(50% - 0.3em) !important;
  width: 1.6em !important;
  height: 1.6em !important;
}

.CompaniesInput div .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding-right: unset !important;
  padding-left: 14px !important;
}

html[dir="rtl"] .CompaniesInput div .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  padding-left: unset !important;
  padding-right: 14px !important;
}

.CountriesInputError {
  font-family: "Cairo" !important;
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

.CitiesInputError {
  font-family: "Cairo" !important;
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

html[dir="rtl"] .CompaniesInput div .MuiSelect-icon {
  left: 7px !important;
  right: unset !important;
}

.companyEye {
  font-size: 23px !important;
  color: #324356 !important;
  margin-right: 5px;
}

html[dir="rtl"] .companyEye {
  margin-left: 5px;
  margin-right: unset;
}

.CompanyActions-row {
  display: flex;
  flex-direction: row;
}

.Company-rowTitle {
  font-size: 16px !important;
}

.CompanyActions-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px !important;
  color: #1777fb !important;
}

.CompanyActions-stopping {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #ad0000 !important;
  font-size: 20px;
}

.CompanyActions-square-stop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #ad0000;
  height: 29px !important;
  width: 29px !important;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: unset;
}

html[dir="rtl"] .CompanyActions-square-stop-container {
  margin-left: 3px;
  margin-right: unset;
}

.CompanyActions-square-stop {
  height: 12px !important;
  width: 12px !important;
  background-color: #fff;
}

.CompanyActions-playCircle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  height: 29px !important;
  width: 29px !important;
  margin-right: 3px;
  margin-left: unset;
}

html[dir="rtl"] .CompanyActions-playCircle-container {
  margin-left: 3px;
  margin-right: unset;
}

.CompanyActions-activate {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #28a745 !important;
  font-size: 20px;
}

.CompanyActions-playIcon {
  font-size: 29px !important;
}

.CompanyRow-title {
  color: #656565;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.Company-creditAmount {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #324356 !important;
}

.CompanyHead-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.AccountsHead-title {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.CompanyListHeader {
  margin-top: 36px;
}

.CompanyListHeader-title {
  font-size: 24px !important;
  color: #656565 !important;
  font-weight: 700 !important;
}

.CompanyFilterContainer {
  border-right: 1px solid #d6d6d6;
  border-left: 1px solid #d6d6d6;
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 13px;
}

.css-1okebmr-indicatorSeparator {
  background-color: unset !important;
}

.CompanyReject-button {
  background-color: #ad0000;
  border-color: #ad0000;
}

.CompanyAccepted-button {
  background-color: #00B437;
  border-color: #00B437;
}
/* .greenStatus{
    border-left: 4px solid #00B437;
    border-right:none;
}
[dir="rtl"] .greenStatus{
    border-right: 4px solid #00B437;
    border-left:none;
}
.orangeStatus{
    border-left: 4px solid #F28F31;
    border-right:none;
}
[dir="rtl"] .orangeStatus{
    border-right: 4px solid #F28F31;
    border-left:none;
}
.redStatus{
    border-left: 4px solid #FF5F59;
    border-right:none;
}
[dir="rtl"] .redStatus{
    border-right: 4px solid #FF5F59;
    border-left:none;
} */
/* .transLogo{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #ccd5db;
    margin-inline-end: 3px;
}
 */

.CompaniesList-orders {
  margin-top: 36px;
  background-color: #f2f2f2;
  border: 1px solid #d6d6d6 !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CompaniesList-title {
  margin-bottom: 0px;
}

.CompaniesList-LinkNotActive {
  background-color: #ffffff !important;
  font-size: 21px !important;
  font-weight: 400 !important;
  color: #5a5a5d !important;
  padding: 6px 20px !important;
}

.CompaniesList-LinkActive {
  background-color: #1677f6 !important;
  font-size: 21px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
}

.CompaniesList-head {
  background-color: #656565;
  color: #ffffff !important;
}

.CompaniesList-table {
  margin-top: 0px !important;
}

.CompaniesList-table table tbody tr td {
  min-height: 90px;
}

.CompaniesList-button {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.CompanyRow-OrangeColor {
  color: #F28F31 !important;
}

.CompanyRow-RedColor {
  color: #FF5F59 !important;
}

.CompanyRow-GreenColor {
  color: #00B437 !important;
}

.CompanyRow-last {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.CompanyRow-arrow {
  color: #324356;
  font-size: 21px;
}

.CompanyRow-first-GreenColor {
  display: flex;
  border-right: 6px solid #00B437 !important;
}

.CompanyRow-first-OrangeColor {
  display: flex;
  border-right: 6px solid #F28F31 !important;
}

.CompanyRow-first-RedColor {
  display: flex;
  border-right: 6px solid #FF5F59 !important;
}

.AdminContainer-custom {
  padding-left: 0px ;
  padding-right: 0px ;
  max-width: 1650px ;
}
.reservationVeiw{
  background-color: #E8E8E8;
}

.orders-check-time {
  color: #656565;
}

.CompaniesList-LinkExcel {
  background-color: #00B437 !important;
  font-size: 21px !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding: 6px 20px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.ExcelIcon {
  width: 26px !important;
  height: 26px !important;
  margin-left: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .AdminContainer-custom {
    max-width: unset; /** 1320px*/
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .CompanyHead-title {
    font-size: 12px !important;
  }

  .CompanyActions-link {
    font-size: 14px !important;
  }

  .CompanyActions-stopping {
    font-size: 14px;
  }

  .CompanyRow-title {
    font-size: 12px !important;
  }

  .CompanyActions-activate {
    font-size: 14px;
  }

  .Company-creditAmount {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1399px) {
  .AdminContainer-custom {
    max-width: unset; /** 1320px*/
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .CompanyHead-title {
    font-size: 13px !important;
  }

  .CompanyActions-link {
    font-size: 16px !important;
  }

  .CompanyActions-stopping {
    font-size: 16px;
  }

  .CompanyRow-title {
    font-size: 14px !important;
  }

  .CompanyActions-activate {
    font-size: 16px;
  }

  .Company-creditAmount {
    font-size: 14px !important;
  }
}