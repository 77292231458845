// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f4f4f4;
$gray-150: #e8e8e8;
$gray-200: #eae8e9;
$gray-250: #c8c7c7;
$gray-300: #acacac;
$gray-400: #dee1e3;
$gray: #c6cbd0;
$dark-blue: #324356;
$blue: #1677f6;
$red: #ff5f59;
$orange: #f28f08;
$green-100: #00b43740;
$green: #00b437;
$goldColor: gold;
$orangColor: #dc7542;
$blueBlack: #354f5c;
$whiteBlue: #edf2f7;
$brwon: #656565;
$dark-gray: #686d71;

// typogarphy
.text_blue-dark {
    color: $dark-blue;
}
.text-blue {
    color: $blue;
}
.text-brwon {
    color: $brwon;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    01. Text Colors
---------------------------------------------------------------------------------------------------------------------------------------------------- */
.text-gray-100 {
    color: $gray-100;
}

.text-gray-200 {
    color: $gray-200;
}

.text-gray-300 {
    color: $gray-300;
}

.text-dark-blue {
    color: $dark-blue;
}

.text-blue {
    color: $blue;
}

.text-red {
    color: $red;
}

.text-orange {
    color: $orange;
}

.text-green {
    color: $green !important;
}

.text-green-100 {
    color: $green-100 !important;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
      02. Background Colors
  ---------------------------------------------------------------------------------------------------------------------------------------------------- */
.bg-gray-100 {
    background-color: $gray-100;
}

.bg-gray-150 {
    background: $gray-150 !important;
}

.bg-gray-200 {
    background-color: $gray-200;
}

.bg-gray-300 {
    background-color: $gray-300;
}

.bg-dark-blue {
    background-color: $dark-blue;
}

.bg-blue {
    background-color: $blue;
}

.bg-red {
    background-color: $red;
}

.bg-orange {
    background-color: $orange;
}

.bg-green-100 {
    background-color: $green-100 !important;
}

.bg-green {
    background-color: $green;
}

.bg-orange {
    background-color: $orange !important;
}

.bg-success {
    background-color: $green !important;
}

.bg-primary {
    background-color: $blue !important;
}

.bg-red {
    background-color: $blue !important;
}

.bg-gray {
    background: #e8e8e8 !important;
}

.bg-gray-100 {
    background: $gray-100 !important;
}

.bg-dark-gray {
    background: $dark-gray !important;
}
