.text_filed {
    border: 1px solid #c6cbd0;
    padding: 2px;
    border-radius: 5px;
    box-shadow: none;
    font-size: 16px;
}
.error_border {
    border: 1px solid #ad0a0a !important;
}
.text_input {
    width: 100%;
    outline: none;

    font-size: 16px;
    box-shadow: none;
    border: none;
}

.text_input::placeholder {
    font-size: 14px;
    padding: 0.3rem;
    color: #112233;
    font-weight: 400;
}
.isDisabled{
    background-color: #f2f2f2 !important;
}
.DateInput_input {
    width: 100%;
    outline: none;
    padding: 0rem !important;
    font-size: 16px !important;
    box-shadow: none;
    border: none;
    font-weight: 400 !important;
    border: none !important;
}
.DateInput {
    width: 100% !important;
}
.DateInput_input::placeholder {
    color: #112233;
    padding: 0rem 0.4rem !important ;
    font-size: 14px;
}

.SingleDatePickerInput,
.SingleDatePicker {
    width: 100% !important;
    z-index: 4 !important;
}
.select_field {
    background-color: rgb(255, 255, 255);
    width: 100% !important;
    border-radius: 5px !important;
    padding-top: 0.5rem !important;
    border: 1px solid #f2f2f2 !important;
    font-size: 14px;
}
.select_field--label {
    background-color: rgb(255, 255, 255);
    width: 100% !important;
    position: relative;
    border-radius: 5px !important;
    padding-top: 1.1rem !important;

    font-size: 14px;
}

.css-1s2u09g-control {
    border: none !important;
    align-items: end !important;
}
.css-19jh2ze-option,
.css-165luzk-option {
    color: #112233 !important;
}
.css-14dclt2-Input {
    margin: 0 !important;
    padding: 0 !important;
}
.css-14el2xx-placeholder {
    color: gray !important;
    padding: 0rem 0.4rem !important ;
    font-weight: 400 !important;
}
.css-26l3qy-menu {
    z-index: 5 !important;
}
.control-field__label {
    z-index: 1;
    position: absolute;
    margin: 0;
    padding: 0.5rem !important;
    font-size: 13px !important;
    color: #000;
}
.control-field {
    z-index: 1;
    margin: 0;
    padding: 0rem 0.4rem;
    font-size: 13px !important;
    color: #000;
}

.bg-disabled {
    background-color: #f2f2f2 !important;
    .css-1s2u09g-control {
        background: #f2f2f2 !important;
    }
    .DateInput_input__disabled {
        background: #f2f2f2 !important;
    }
    .SingleDatePickerInput__disabled {
        background: #f2f2f2 !important;
    }
}

.icons_label {
    font-size: 28px;
    color: #757575;
}
.icons {
    color: #d6d6d6;
    font-size: 20px;
    margin: 0.4rem 0rem;
}

.radio_bnt {
    width: 25px;
    height: 25px;
}

input[type="radio"] {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border: 2px solid #656565;
    border-radius: 50%;
    outline: none;
}
input[type="radio"]:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: 2px;
    margin-right: 2px;
    margin-left: 2px;
}

input[type="radio"]:checked:before {
    background: #1677f6;
}
input[type="radio"]:checked {
    border: 3px solid #1677f6;
}
.password input,
input[type="password"] {
    width: 97%;
    border: 0;
    padding-inline-start: 4px;
}
.password .eye {
    position: absolute;
    right: 2rem;
    top: 1.3rem;
}
div .css-k008qs{
    direction: ltr !important;
}

.Admintext_filed {
    border: 1px solid #EAE8E9;
    padding: 2px;
    border-radius: 5px;
    box-shadow: none;
    font-size: 16px;
}