.wallet {
  background-image: url("../../../assets/images/wallet.png");
  background-position: 0 10em;
  background-repeat: no-repeat;

  .balance {
 
    display: flex;
    align-items: center;

    .current {
      font: normal normal 600 28px/30px Cairo;
      letter-spacing: 0px;
      color: #324356;
    }
    .amount {
      font: normal normal bold 30px/32px Roboto;
      letter-spacing: 0px;
      color: #00b437;
      span {
        font: normal normal normal 24px/25px Cairo;
        letter-spacing: 0px;
        color: #324356;
      }
    }

    .add {
      width: 200px;
      height: 60px;
      background: #00b437 0% 0% no-repeat padding-box;
      border-radius: 5px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tabs {
    margin: 1rem 0;
    .tab {
      border: 1px solid #c6cbd0;
      border-radius: 5px;
      width: 200px;
      height: 60px;
      font-size: 1.2rem;
      &:first-child {
        margin-inline-end: 1rem;
      }
      &.active {
        color: #fff;
        background: #0184fe 0% 0% no-repeat padding-box;
      }
    }
  }
}

.filter {
  padding: 0.8em;
  background: #d3d9de;
  > div {
    margin-inline-end: 1rem;
    width: 300px;
  }
  .text_filed {
    height: 40px;
    background: white;
    > div {
      height: 35px;
    }
  }
  .select_field {
    padding: 0 !important;
    height: 35px !important;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .search {
    background: #0184fe;
    border-radius: 4px;
    width: 100px;
    height: 35px;
    color: #fff;
  }

  .reset {
    color: #6e6e6e;
  }
}

.walletRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.AmountContent {
  padding: 16px !important;
}
.AmountTitle {
  font-size: 16px !important;
}
.BalanceHeader {
  font-size: 23px !important;
}
.BalanceContent {
  padding: 12px !important;
}
.AmountNumberSpan {
  font-size: 20px !important;
}
.AmountNumber {
  margin: 5px 0 5px 0 !important;
  font-size: 23px !important;
}
.AmountPound {
  font-size: 16px !important;
}
