.cost_box{
    background-color: #C9DFFD;
    width: 18rem;
}
.lan{
    font-family: "Cairo";
}
html[dir="rtl"] .cost_box{
    text-align: right;
}
.delete:hover{
    color: #dc3545!important ;
    cursor: pointer;
    transition: ease-in-out 0.2s;
}