.main-bar {
    margin: auto;
    max-width: 800px;
    width: 80%;

    &__progress {
        outline-width: 8px;
        outline-color: $white;
        outline-style: solid;
        height: 10px;
    }

    &__box {
        display: flex;
        justify-content: space-between;
        margin-top: -32px;
    }

    &__item {
        width: 50px;
        height: 50px;
        line-height: 50px;
        border: 1px solid $gray-100;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        color: $gray-300;
        z-index: 2;

        .fa {
            font-size: 1.5rem;
        }

        &.active {
            border: 1px solid $blue;

            .fa {
                color: $blue;
            }
        }

        &.current {
            border: 1px solid $blue;
            color: $blue;
            .fa {
                color: $blue;
            }
        }
        .progress-text {
            position: absolute;
            left: -1rem;
            width: 90px;
            font-size: 12px;
            top: 2.2rem;
        }
        .fa-plane {
            [dir="rtl"] & {
                transform: rotateZ(180deg);
            }
        }
    }
}

.back-btn {
    &:hover {
        color: gray;
    }
}
