.bg-medical {
  background-color: #f6f9fc;
}
.bg-pay {
  background-color: #f7dec7;
  border-radius: 5px;
}
.text-pay{
    color: #B55800;
}
.medical_box{
    background-color: #F4F8FF;

}