.create-request {
  height: 100vh;
  .card {
    height: 400px;
    border: 1px solid #eae8e9;
    background: #fff;
    padding: 3rem 5rem;
    align-items: center;
    margin-top: 7em;
    img {
      width: 70px;
    }
    input,
    .text_filed,
    .add-credit {
      width: 100%;
    }
    .add-credit {
      background: #00b437;
      color: #fff;
      height: 35px;
      border-radius: 5px;
    }
  }
}

.upload-images {
  border: 2px dashed #e5e5e5;
  height: 70px;
  .chose {
    background: #1777fb;
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
    color: white;

    [dir="rtl"] & {
      right: auto;
      left: 10px;
    }
  }
  #uploadFile {
    display: block;
    width: 100%;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  [dir="rtl"] & {
    .text-left {
      text-align: right !important;
    }
  }
}

.info {
  width: 350px;
  justify-content: center;
  button.back {
    background: #696e72;
    color: #fff;
    border: 1px solid #c6cbd0;
    border-radius: 5px;
    width: 180px;
    height: 40px;
    font-size: 1.2rem;
  }
}

.WalletRequestInput {
  width: 100%;
  font-size: 16px;
  font-family: Cairo;
  color: #112233;
}

.WalletRequestInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  font-size: 16px;
  font-family: Cairo;
  color: #112233;
}

html[dir="rtl"] .WalletRequestInput .MuiInputLabel-filled.MuiInputLabel-shrink {
  left: unset;
  right: 16px;
  font-size: 16px;
  font-family: Cairo;
  color: #112233;
}

.WalletRequestInput .MuiFilledInput-input {
  color: rgba(0, 0, 0, 0.65);
}

.WalletRequestInput .MuiFilledInput-root {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  margin-bottom: 15px !important;
}

.WalletRequestInput .MuiFilledInput-root:hover {
  background-color: #fff;
}

.WalletRequestInput .MuiFilledInput-root.Mui-focused {
  background-color: #fff;
}

.WalletRequestInput .MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.WalletRequestInput .MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.WalletRequestInput .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.54);
}

.WalletRequestInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}

.WalletRequestInput .MuiFormHelperText-root.Mui-error {
  color: #ff5f59 !important;
  margin-left: 0px !important;
}

html[dir="rtl"] .WalletRequestInput .MuiFormHelperText-root.Mui-error {
  text-align: right;
  margin-right: 0px !important;
}

.WalletRequestInputError .MuiFilledInput-root {
  border-color: #ff5f59 !important;
  margin-bottom: 0px !important;
}

/* Chrome, Safari, Edge, Opera */
.WalletRequestInput div input::-webkit-outer-spin-button,
.WalletRequestInput div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.WalletRequestInput div input[type="number"] {
  -moz-appearance: textfield;
}

.WalletCard {
    background-color: #fff !important;
    border: 1px solid #EAE8E9 !important;
}

.walletPayAmountContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 16px;
}

.walletPayAmountContent {
    display: flex;
    flex-direction: column;
    background-color: #DEEAF3 !important;
    border-radius: 5px;
    width: 50%;
    margin-left: 5px;
    padding: 10px 0px 10px 0px;
    align-items: center;
}

.walletPayAmountHeader {
    font-family: 'Cairo';
    font-size: 16px;
    color: #112233;
    margin: 0px;
    padding: 0px;
}

.walletPayAmountPound {
    font-family: 'Cairo';
    font-size: 18px;
    color: #112233;
    margin: 0px;
    padding: 0px;
    font-weight: 700;
}

.WalletSafaPayForm {
    width: 100%;
}