.wallet {
  background-image: url("../../../../assets/images/wallet.png");
  background-position: 0 10em;
  background-repeat: no-repeat;
}
.balance {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-width {
  width: 36.5%;
}
.to {
  position: absolute;
  top: 4px;
}
