@import "./colors.scss";

html,
:root {
  font-size: 14px !important;
}

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    02. Border Radius ( 10 : 100 )
---------------------------------------------------------------------------------------------------------------------------------------------------- */
@for $i from 1 through 10 {
  .radius-#{$i*10} {
    border-radius: #{$i * 10}px !important;
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
  color: white;
  display: inline-block;
}
a:hover {
  text-decoration: none !important;
  color: white;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
button {
  outline: none;
  border: none;
  background-color: transparent;
}

.border {
  border: 1px solid $gray;
}
ul {
  list-style: none;
}

.selected-border {
  border-color: $blue !important;
}

.border-y {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}
.border-x {
  border-left: 1px solid $gray;
  border-right: 1px solid $gray;
}
.border-t {
  border-top: 1px solid $gray;
}
.border-b {
  border-bottom: 1px solid $gray;
}
.text-normal {
  font-weight: 400 !important ;
}
.text-bold {
  font-weight: 600;
}
.text-bolder {
  font-weight: 700;
}

.border-circle {
  border: 3px solid $gray;
  border-radius: 50%;
}
.header {
  background-color: $dark-blue;
  border-bottom: 1px solid $gray;
}

/* Subnav button */
.subnav .subnavbtn {
  font-size: 1rem;
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Add a red background color to navigation links on hover */
.subnavbtn {
  background-color: #1677f6;
}

.subnavbtn-active {
  background-color: #1677f6 !important;
}

/* Style the subnav content - positioned absolute */
.subnav-content {
  padding: 1rem;
  display: none;
  position: absolute;
  right: auto;
  background-color: #1677f6;
  width: max-content;
  z-index: 1;
}

/* Style the subnav links */
.subnav-content a {
  float: right;
  color: white;
  text-decoration: none;
  margin-bottom: 5px;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
  color: black;
}

/* When you move the mouse over the subnav container, open the subnav content */
.subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

.AdminHeaderLink {
  padding: 1rem;
}